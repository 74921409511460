import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SpeedError.css';

const SpeedError = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const searchParams = new URLSearchParams(window.location.search);
  const userId = searchParams.get('userid');
  const speed = searchParams.get('speed');

  const handleTryAgain = () => {
    navigate(`/?userid=${userId}`); // Navigate to '/' with userId
  };

  const handleTryAgainWs = () => {
    navigate(`/face-scan/?userid=${userId}`)
  }
console.log(speed)
  return (
         <>
                {/* <div className='try-again-container'>
                <h1  className='speed-msg'>Your Internet Speed is Less Than 100 Mbps or Unstable</h1>
                    <button className='try-again-button' onClick={handleTryAgainWs}>Try Again with Same network but it may impact the result</button>
                    <button className='try-again-button' onClick={handleTryAgain}>Try Again with Different network</button>
                </div> */}
                <div className='speed-container'>
                <h1  className='speed-msg'>Your Internet Speed is Less Than 100 Mbps or Unstable</h1>
                  <div className='pop-up-container'>
                    <div className='pop-up-card' onClick={handleTryAgainWs}>
                      <h1 className='prompt-msg'>Try Again with Same network but it may impact the result</h1>
                    </div>
                    <div className='pop-up-card'  onClick={handleTryAgain}>
                      <h1 className='prompt-msg'>Try Again with Different network</h1>
                    </div>
                  </div>
                </div>
         </>
    );
};

export default SpeedError;
