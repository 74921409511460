import React, { useState, useEffect } from 'react';
import './Loader.css';

const Loader = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  const messages = [
    'We’re loading an awesome module onto your device...',
    'Using this, your web browser will turn into a remote monitoring tool...',
    'Using AI, we will be able to understand your physiology...',
    'Please wait. This won’t take long...'
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 2500);

    return () => {
      clearInterval(intervalId);
    };
  }, [messages.length]);

  return (
    <div className='loader-msg'>
      <p>{messages[currentMessageIndex]}</p>
    </div>
  );
};

export default Loader;
