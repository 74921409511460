import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import WebcamCapture from '../../Components/WebcamCapture/WebcamCapture';
import axios from 'axios';

const FaceScan = () => {
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const userId = searchParams.get('userid');
  const [socketClient, setSocketClient] = useState(null);
  const [start, setStart] = useState(false);
  const [scriptCompleted, setScriptCompleted] = useState(false);

  useEffect(() => {
    // const socket = new WebSocket('ws://154.41.253.186:4000');
  //  const socket = new WebSocket('ws://20.124.20.8:4000');
  // const socket = new WebSocket('wss://face-scan-web.actofit.com:4000');

  //   socket.onopen = () => {
  //     console.log(`Connected to server with user ID: ${userId}`);
  //     socket.send(JSON.stringify({ type: 'setUserId', id: userId }));
  //     setSocketClient(socket);
  //   };

  //   socket.onclose = () => {
  //     if (!scriptCompleted) {
  //       console.log('Disconnected from server');
  //       navigate(`/try-again?userid=${userId}`);
  //     }
  //   };

  //   socket.onerror = (error) => {
  //     console.error('Connection error:', error);
  //     if (!scriptCompleted) {
  //       navigate(`/try-again?userid=${userId}`);
  //     }
  //   };

  //   socket.onmessage = (event) => {
  //     const data = JSON.parse(event.data);
  //     if (data.type === 'success') {
  //       setSuccessMessage(data.message);
  //       console.log(data);
  //       navigate(`/result?data=${encodeURIComponent(JSON.stringify(data.message))}&userid=${userId}`);
  //       setScriptCompleted(true);
  //     }
  //   };

    // const heartbeatInterval = setInterval(() => {
    //   if (socket) {
    //     socket.send(JSON.stringify({ type: 'heartbeat' }));
    //   }
    // }, 2000);

    // return () => {
      // if (socketClient) {
      //   socketClient.close();
      // }
      // clearInterval(heartbeatInterval);
    // };
  }, [navigate, userId]);

  const handleCapture = (imageArray, avgFPS) => {
    setStart(!start);
    imageArray.forEach((imageSrc, index) => {
      const base64Image = imageSrc.split(',')[1];
      

      // axios.post('http://192.168.1.4:4001/', { text: base64Image }, {
      //       headers: {
      //         'Content-Type': 'application/json'
      //     }
      //     })
      //       .then(response => {
      //           console.log('Response from server:', response.data);
      //           // Handle response from server as needed

      //           if ( response.data['operation_code'] == 200){
      //             console.log(response.data)
      //           }

      //           if (response.data['operation_code'] == 500){
      //             navigate(`/try-again?userid=${userId}`);
      //           }
      //       })
      //       .catch(error => {
      //           console.error('Error:', error);
      //           // Handle error as needed
      //           navigate(`/try-again?userid=${userId}`);
      //       });
      
      // socketClient.send(JSON.stringify({ type: 'image', userId, index, image: base64Image, avgFPS }));
    });
  };

  return (
    <>
      <WebcamCapture onCapture={handleCapture}/>
      {successMessage && <p>{successMessage}</p>}
    </>
  );
};

export default FaceScan;