import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Score from '../../Components/Score/Score';
import axios from 'axios';
import './Result.css'

const Result = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let dataParam = searchParams.get('data');
  console.log(dataParam)
  //let dataParam = "{'hr': 149, 'ibi': (452, 1), 'sdnn': 144.2, 'rmssd': 222.2, 'pnn20': 87.8, 'pnn50': 70.7, 'hrv': 0.6489094643213754, 'rr': 11.0, 'sysbp': 107, 'diabp': 69, 'spo2': 95, 'vo2max': 19.7, 'si': 1129.0, 'mhr': 193, 'hrr': 43, 'thr': 158, 'co': 11.5, 'map': 81.9, 'hu': 77.6, 'bv': '4756.5415712', 'tbw': 42.867239999999995, 'bwp': 60.4, 'bmi': 24.0, 'bf': 30.6, 'asth_risk': 47.8}\n";
  const userId = searchParams.get('userid');
  const navigate = useNavigate();

  // Replace single quotes with double quotes for keys and values
// dataParam = dataParam.replace(/'/g, '"');

// // Replace tuple-like structure for "ibi" with array format
// dataParam = dataParam.replace(/\((\d+),\s*(\d+)\)/, '[$1, $2]');

// // Remove the trailing newline character
// dataParam = dataParam.trim();

// const data = JSON.parse(dataParam);

// console.log(data);
   
  const jsonMatch = dataParam.match(/\{.*\}/);
    console.log(jsonMatch)
  var data =  {
      'hr': 0,
      'ibi': 0,
      'sdnn': 0, 
      'rmssd': 0, 
      'pnn20': 0, 
      'pnn50': 0, 
      'hrv': 0, 
      'rr': 0, 
      'sysbp': 0, 
      'diabp': 0, 
      'spo2': 0, 
      'vo2max': 0, 
      'si': 0, 
      'mhr': 0, 
      'hrr': 0, 
      'thr': 0, 
      'co': 0, 
      'map': 0, 
      'hu': 0, 
      'bv': 0, 
      'tbw': 0, 
      'bwp': 0, 
      'bmi': 0, 
      'bf': 0, 
      'asth_risk': 0,
      'HB': 0,
      'HBA1C': 0
    }
  if (jsonMatch && jsonMatch[0]) {
    try {
      // Parse the extracted JSON string into a JSON object
       data = JSON.parse(jsonMatch[0].replace(/'/g, '"'));

    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  } else {
    console.error('No valid JSON found in the string.');
  }

  const postData = {
    "_id": "64d22d34a698bede83ddb858",
    "userid": userId,
    "ibi": data.ibi || 0,
    "rmssd": data.rmssd || 0,
    "sdnn": data.sdnn || 0,
    "bpm": data.hr || 0,
    "rr": data.rr || 0,
    "oxygen": data.spo2 || 0,
    "stressStatus": "LOW",
    "systolic": data.sysbp || 0,
    "diastolic": data.diabp || 0,
    "bloodPressureStatus": "NORMAL",
    "bwellScore": data.bwellScore || 0,
    "createdAt": Date.now(),
    "__v": 0
  };

  useEffect(() => {
    const config = {
      method: 'post',
      url: 'https://nucleus.actofit.com:3000/smartscale/v1/bwell/add_bwelldata',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(postData)
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

 
  //  if(data.hr == 0){
  //   navigate(`/try-again?userid=${userId}`)
  //  }
  if (data?.hr !== 0) {
  return (
    <div className="reading-container">
         <div className="nav-button">
            Your Report
         </div>

         <div className="reading">
            <p className="reading-title">Heart Rate</p>
            <p className="reading-value">{data?.hr}</p>
            <p className="reading-unit">BPM</p>
            <div className="reading-range">
                <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
                    <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8"/>
                    {data?.hr <= 60 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
                    {data?.hr > 60 && data?.hr <= 101 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
                    {data?.hr > 101 && data?.hr <= 220 && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
                </svg>
            </div>
            <p className="reading-head low">LOW</p>
            <p className="reading-head normal">NORMAL</p>
            <p className="reading-head high">HIGH</p>
            <p className="reading-threshold bpm-one">30</p>
            <p className="reading-threshold bpm-two">60</p>
            <p className="reading-threshold bpm-three">101</p>
            <p className="reading-threshold bpm-four">220</p>
        </div>
  
        <div className="reading">
            <p className="reading-title">Respiration Rate</p>
            <p className="reading-value">{data?.rr}</p>
            <p className="reading-unit">breaths/min</p>
            <div className="reading-range">
                    <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
                        <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8"/>
                        {data?.rr <= 12 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
                        {data?.rr > 12 && data?.rr <= 21 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
                        {data?.rr > 21 && data?.rr <= 30 && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
                    </svg>
            </div>
            <p className="reading-head low">LOW</p>
            <p className="reading-head normal">NORMAL</p>
            <p className="reading-head high">HIGH</p>
            <p className="reading-threshold bpm-one">6</p>
            <p className="reading-threshold bpm-two">12</p>
            <p className="reading-threshold bpm-three">21</p>
            <p className="reading-threshold bpm-four">30</p>
        </div>
  
      <div className="reading">
        <p className="reading-title">Oxygen Saturation</p>
        <p className="reading-value">{data?.spo2}</p>
        <p className="reading-unit">%</p>
        <div className="reading-range">
                    <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
                        <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8"/>
                        {data?.spo2 <= 91 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
                        {data?.spo2 > 91 && data?.spo2 <= 95 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
                        {data?.spo2 > 95 && data?.spo2 <= 100 && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
                    </svg>
            </div>
        <p className="reading-head low">LOW</p>
        <p className="reading-head concerning">CONCERNING</p>
        <p className="reading-head high">NORMAL</p>
        <p className="reading-threshold bpm-one">80</p>
        <p className="reading-threshold bpm-two">91</p>
        <p className="reading-threshold bpm-three">95</p>
        <p className="reading-threshold bpm-four">100</p>
      </div>
  
      <div className="reading" style={{"height":"212px"}}>
        <p className="reading-title">Blood Pressure</p>
        <p className="reading-value">{data?.sysbp} <span className="units">mmHg</span></p>
        <p className="reading-unit">Systolic</p>
        <div className="reading-range">
                    <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
                        <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8"/>
                        {data?.sysbp <= 100 && <rect x="0.724121" y="0.286621" width="54.81" height="6" fill="#ff4b28" />}
                        {data?.sysbp > 100 && data?.sysbp <= 120 && <rect x="54.81" y="0.286621" width="54.81" height="6" fill="#00ab76" />}
                        {data?.sysbp > 120 && data?.sysbp <= 139 && <rect x="109.62" y="0.286621" width="54.81" height="6" fill="#ffe400" />}
                        {data?.sysbp > 139 && data?.sysbp <= 200 && <rect x="164.43" y="0.286621" width="54.81" height="6" fill="#ff4b28" />}
                    </svg>
            </div>
        <p className="reading-head lows">LOW</p>
        <p className="reading-head normals">NORMAL</p>
        <p className="reading-head aboves">ABOVE N</p>
        <p className="reading-head highs">HIGH</p>
        <p className="reading-threshold bp-one">50</p>
        <p className="reading-threshold bp-two">100</p>
        <p className="reading-threshold bp-three">120</p>
        <p className="reading-threshold bp-four">139</p>
        <p className="reading-threshold bp-five">220</p>
        
        <div className="diastolic">
          <p className="reading-value">{data?.diabp}<span className="units">mmHg</span></p>
          <p className="reading-unit">Diastolic</p>
          <div className="reading-range">
                    <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
                        <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8"/>
                        {data?.diabp <= 70 && <rect x="0.724121" y="0.286621" width="54.81" height="6" fill="#ff4b28" />}
                        {data?.diabp > 70 && data?.diabp <= 80 && <rect x="54.81" y="0.286621" width="54.81" height="6" fill="#00ab76" />}
                        {data?.diabp > 80 && data?.diabp <= 90 && <rect x="109.62" y="0.286621" width="54.81" height="6" fill="#ffe400" />}
                        {data?.diabp > 90 && data?.diabp <= 120 && <rect x="164.43" y="0.286621" width="54.81" height="6" fill="#ff4b28" />}
                    </svg>
            </div>
          <p className="reading-head lows">LOW</p>
          <p className="reading-head normals">NORMAL</p>
          <p className="reading-head aboves">ABOVE N</p>
          <p className="reading-head highs">HIGH</p>
          <p className="reading-threshold bp-one">30</p>
          <p className="reading-threshold bp-two">70</p>
          <p className="reading-threshold bp-three">80</p>
          <p className="reading-threshold bp-four">90</p>
          <p className="reading-threshold bp-five">120</p>
        </div>
        
      </div>
  
      <div className="reading">
        <p className="reading-title">SDNN</p>
        <p className="reading-value">{data?.sdnn}</p>
        <p className="reading-unit">ms</p>
        <div className="reading-range">
                    <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
                        <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8"/>
                        {data?.sdnn <= 30 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
                        {data?.sdnn > 30 && data?.sdnn <= 97 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
                        {data?.sdnn > 97  && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
                    </svg>
            </div>
        <p className="reading-head low">LOW</p>
        <p className="reading-head concerning">NORMAL</p>
        <p className="reading-head high">HIGH</p>
        <p className="reading-threshold bpm-one">0</p>
        <p className="reading-threshold bpm-two">30</p>
        <p className="reading-threshold bpm-three">97</p>
        <p className="reading-threshold bpm-four">150</p>
      </div>
  
      <div className="reading">
        <p className="reading-title">RMSSD</p>
        <p className="reading-value">{data?.rmssd}</p>
        <p className="reading-unit">ms</p>
        <div className="reading-range">
                    <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
                        <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8"/>
                        {data?.rmssd <= 20 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
                        {data?.rmssd > 20 && data?.rmssd <= 90 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
                        {data?.rmssd > 90  && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
                    </svg>
            </div>
        <p className="reading-head low">LOW</p>
        <p className="reading-head concerning">NORMAL</p>
        <p className="reading-head high">HIGH</p>
        <p className="reading-threshold bpm-one">0</p>
        <p className="reading-threshold bpm-two">20</p>
        <p className="reading-threshold bpm-three">90</p>
        <p className="reading-threshold bpm-four">150</p>
      </div>
  
      <div className="reading">
        <p className="reading-title">IBI</p>
        <p className="reading-value">{data?.ibi}</p>
        <p className="reading-unit">ms</p>
        <div className="reading-range">
                    <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
                        <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8"/>
                        {data?.ibi <= 640 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
                        {data?.ibi > 640 && data?.ibi <= 900 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
                        {data?.ibi > 900 && data?.ibi <= 3000 && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
                    </svg>
            </div>
        <p className="reading-head low">LOW</p>
        <p className="reading-head concerning">NORMAL</p>
        <p className="reading-head high">HIGH</p>
        <p className="reading-threshold bpm-one">0</p>
        <p className="reading-threshold bpm-two">640</p>
        <p className="reading-threshold bpm-three">900</p>
        <p className="reading-threshold bpm-four">3000</p>
      </div>

      <div className="reading">
      <p className="reading-title">Asthama Risk Score</p>
      <p className="reading-value">{data?.asth_risk}</p>
      <p className="reading-unit">ms</p>
      <div className="reading-range">
                  <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
                      <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8"/>
                      {data?.asth_risk <= 50 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
                      {data?.asth_risk > 50 && data?.asth_risk <= 80 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
                      {data?.asth_risk > 80 && data?.asth_risk <= 100 && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
                  </svg>
          </div>
      <p className="reading-head low">NORMAL</p>
      <p className="reading-head concerning">ELEVATED</p>
      <p className="reading-head high">HIGH</p>
      <p className="reading-threshold bpm-one">0</p>
      <p className="reading-threshold bpm-two">50</p>
      <p className="reading-threshold bpm-three">80</p>
      <p className="reading-threshold bpm-four">100</p>
    </div>

    <div className="reading">
      <p className="reading-title">Hemoglobin (beta)</p>
      <p className="reading-value">{data?.HB}</p>
      <p className="reading-unit">gm/dL</p>
      <div className="reading-range">
                  <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
                      <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8"/>
                      {data?.HB <= 12.7 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
                      {data?.HB > 12.7 && data?.HB <= 17 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
                      {data?.HB > 17 && data?.HB <= 25 && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
                  </svg>
          </div>
      <p className="reading-head low">LOW</p>
      <p className="reading-head concerning">NORMAL</p>
      <p className="reading-head high">HIGH</p>
      <p className="reading-threshold bpm-one">0</p>
      <p className="reading-threshold bpm-two">12.7</p>
      <p className="reading-threshold bpm-three">17</p>
      <p className="reading-threshold bpm-four">25</p>
    </div>

    <div className="reading">
      <p className="reading-title">HbA1c (beta)</p>
      <p className="reading-value">{data?.HBA1C}</p>
      <p className="reading-unit">mmol/L</p>
      <div className="reading-range">
                  <svg xmlns="http://www.w3.org/2000/svg" width="220" height="7" viewBox="0 0 220 7" fill="none">
                      <rect x="0.724121" y="0.286621" width="219.261" height="6" fill="#E8E8E8"/>
                      {data?.HBA1C <= 5.9 && <rect x="0.724121" y="0.286621" width="73.087" height="6" fill="#00ab76" />}
                      {data?.HBA1C > 5.9 && data?.HBA1C <= 6.7 && <rect x="73.087" y="0.286621" width="73.087" height="6" fill="#ffe400" />}
                      {data?.HBA1C > 6.7 && data?.HBA1C <= 18 && <rect x="146.5" y="0.286621" width="73.087" height="6" fill="#ff4b28" />}
                  </svg>
          </div>
      <p className="reading-head low">OPTIMAL</p>
      <p className="reading-head concerning">ELEVATED</p>
      <p className="reading-head high">HIGH</p>
      <p className="reading-threshold bpm-one">0</p>
      <p className="reading-threshold bpm-two">5.9</p>
      <p className="reading-threshold bpm-three">6.7</p>
      <p className="reading-threshold bpm-four">18</p>
    </div>

    <div className="congras" style={{"textAlign":"center","width":"355.564px"}}>
    Click to download the app and transform your fitness journey
    </div>

    <div className="images-container">
      <div className="top-row">
          <a href="https://apps.apple.com/us/app/actofit-health/id1317677263" target="_blank" rel="noopener noreferrer">
            <img src="assests/appstore.png" alt="Image 1" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.actofitSmartScale&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
            <img src="assests/playstore.png" alt="Image 2" />
          </a>
      </div>
      <div className="bottom-row">
          <a href="https://actofit.com/products/smart-transform" target="_blank" rel="noopener noreferrer">
            <img src="assests/stp.jpeg" className="stp" alt="Image 3" />
          </a>
        </div>
    </div>
  
      <div className="congras">
        <span style={{"fontWeight": "bold"}}>Disclaimer:</span> For Investigational Use Only. Actofit is not a substitute for the clinical judgment of a healthcare professional. Actofit is intended to improve your awareness of general wellness. Actofit does not diagnose, treat, mitigate or prevent any disease, symptom, disorder or abnormal physical state. Consult with a health care professional or emergency services if you believe you may have a medical issue.
      </div>
    </div>
  );
 }else {
  return <>Loading</>;
}
};

export default Result;