import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Form.css';
import "bootstrap/dist/css/bootstrap.min.css";

const getUploadSpeed = () => {
  if (navigator.connection && navigator.connection.downlink) {
    const downlinkSpeed = navigator.connection.downlink; // in Mbps
    const uploadSpeed = downlinkSpeed ; // Convert Mbps to MB/s

    return uploadSpeed.toFixed(2)
  } else {
    return 1
  }
};

const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    height: '',
    weight: '',
    age: '',
    gender: '',
    phoneNumber: ''
  });
  //const searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  //const userId = searchParams.get('userid');

  const phoneNumberRef = useRef(null);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value
    }));
  };


  const validateForm = () => {
    const { name, height, weight, age, gender, phoneNumber } = formData;
    // Check if all fields are filled
    if (
      name.trim() === '' ||
      height.trim() === '' ||
      weight.trim() === '' ||
      age.trim() === '' ||
      gender.trim() === '' ||
      phoneNumber.trim() === ''
    ) {
      return false;
    }
    // Check if numeric fields have numeric inputs
    if (
      isNaN(parseFloat(height)) ||
      isNaN(parseFloat(weight)) ||
      isNaN(parseInt(age))
    ) {
      return false;
    }

    return true;
  };

  const handleClick = () => {

    // const { phoneNumber } = formData;

    const speed = getUploadSpeed()

    if(speed < 0.5){
      navigate(`/speed-error?speed=${speed}&userid=${formData.phoneNumber}`);
    }else{
      if (validateForm()) { // checking all fields filled
        console.log(formData)
        navigate(`/face-scan?userid=${formData.phoneNumber}&name=${formData.name}&height=${formData.height}&weight=${formData.weight}&age=${formData.age}&gender=${formData.gender}`);
      } else {
        alert('Please fill in all fields');
      }
    }
   
  };

  return (
    <div className="form-container">
      <div className="column1">
        <div
          style={{
            border: "0px solid red",
            fontWeight: 600,
            fontSize: 30,
            marginBottom: "3vh",
            color: "#1cae81",
          }}
        >
          Registration Form
        </div>
        <div>
          <input
            id="name"
            type="text"
            className="shadow input-field-style"
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <input
            id="height"
            type="number"
            className="shadow input-field-style"
            placeholder="Enter your height (in cm)"
            value={formData.height}
            onChange={handleChange}
          />
        </div>
        <div>
          <input
            id="weight"
            type="number"
            className="shadow input-field-style"
            placeholder="Enter your weight (in kg)"
            value={formData.weight}
            onChange={handleChange}
          />
        </div>
        <div>
          <input
            id="age"
            type="number"
            className="shadow input-field-style"
            placeholder="Enter your age"
            value={formData.age}
            onChange={handleChange}
          />
        </div>
        <div>
          <select
            id="gender"
            className="shadow dropdown-field-style"
            value={formData.gender}
            onChange={handleChange}
          >
            <option value="">Select your gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <div>
          <input
            id="phoneNumber"
            ref={phoneNumberRef}
            type="tel"
            className="shadow input-field-style"
            placeholder="Enter your phone number"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </div>
        <button className="next-button" onClick={handleClick}>click me</button>
      </div>
    </div>
  );
};

export default Form;
