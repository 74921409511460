// const UploadSpeedTest = () => {
//     const handleFileChange = () => {
//         getUploadSpeed();
//     };
  
//     return (
//       <div>
//         <input type="file" onChange={handleFileChange} />
//       </div>
//     );
//   };
  
// export default UploadSpeedTest;

//   const getUploadSpeed = () => {
//     if (navigator.connection && navigator.connection.downlink) {
//       const downlinkSpeed = navigator.connection.downlink; // in Mbps
//       const uploadSpeed = downlinkSpeed * 0.125; // Convert Mbps to MB/s
  
//       console.log(`Estimated upload speed: ${uploadSpeed.toFixed(2)} MB/s`);
//     } else {
//       console.error('Connection API not supported');
//     }
//   };
  

import React, { useRef } from 'react';

function UploadSpeedTest() {
  const fileInputRef = useRef(null);
       
  const handleUpload = async () => {
    const file = fileInputRef.current.files[0];
        
    if (file) {
        
      const startTime = performance.now();
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await fetch('https://face-scan-web.actofit.com:4000/upload', {
          method: 'POST',
          body: formData,
        });
        const endTime = performance.now();
        const uploadTime = endTime - startTime;
        const speedBps = (file.size / uploadTime) * 1000; // bytes per second
        const speedMbps = (speedBps * 8) / 1e6; // Mbps (1e6 is 1,000,000)

        console.log(`Upload speed: ${speedMbps.toFixed(2)} Mbps`);
      } catch (error) {
        console.error('Upload failed:', error);
      }
    }else{
        console.log("err")
    }
  };

  return (
    <div>
      <input type="file" ref={fileInputRef} />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
}

export default UploadSpeedTest;
